// JavaScript Document

$('.swiper-container').each(function(){
	var slider = $(this);
	var timeout = $(this).data('timeout') ? $(this).data('timeout') : 5000;

	var swipper = new Swiper('.swiper-container', {
		pagination: '.swiper-pagination',
		paginationClickable: true,
		autoplay: timeout,
		speed:600,
		autoplayDisableOnInteraction:false
	});

});


$(document).ready(function(){
	$('#show-menu').click( function(){
		$('#menu').toggleClass('active');
	}) 

	$("#nav > li > a").click(function(){ 
		$('#nav > li > a').removeClass('active');
		$(this).toggleClass('active');

		if(false == $(this).next().is(':visible')) {
			$('#nav ul').slideUp(300);

		}
		$(this).next().slideToggle(300);

		if(false == $(this).next().is(':visible'))  {
			$('#nav > li > a').removeClass('active');
		}
	});
})



$('.product-tmb-img > a').click( function(){
	var imgpath = $(this).attr('dir');
	$('.product-tmb-img > a').removeClass('active');
	$(this).addClass('active');
	$('#main-img').html('<img src='+imgpath+'>');
});


var counter = $('.add-value').val();
$('.cart-minus').click(function(){
	counter--;
	$('.add-value').val(counter)
})

$('.cart-plus').click(function(){
	counter++;
	$('.add-value').val(counter)
})
